const config = {
  environments: {
    preprod: {
      monolith: 'https://api.selency-staging.com',
      gateway: 'https://back-v2-gateway.selency-staging.com',
      product: 'https://product.api-legacy.selency-staging.com',
      admin: 'https://admin.selency-staging.com/admin',
      legacy: {
        origin: 'https://d1h5h668bsdjuk.cloudfront.net',
      },
    },
  },

  env: 'preprod',

  get production() {
    return this.env === 'production';
  },

  warning() {
    return window.location.host !== 'bo.brocantelab.com';
  },

  get gateway() {
    return this.environments[this.env].gateway;
  },

  get monolith() {
    return this.environments[this.env].monolith;
  },

  get product() {
    return this.environments[this.env].product;
  },

  get legacy() {
    return this.environments[this.env].legacy;
  },

  url(path) {
    return `${this.gateway}${path}`;
  },

  monolithApi(path) {
    return `${this.monolith}${path}`;
  },

  productApi(path) {
    return `${this.product}${path}`;
  },

  shopApi(path) {
    return `${this.direct.shopApi}${path}`;
  }
};

export default config;
